import styled from "styled-components";

export const InputContainer = styled.div`
  input {
    background: ${(props) => props.theme.color.input.background};
    border: 1px solid ${(props) => props.theme.color.secondary};
    padding: 5px 10px;
    color: ${(props) => props.theme.color.input.text};
    position: relative;
    z-index: 1;
    left: -13px;
    margin-right: -40px;
    height: 40px;
    width: 100%;
  }
`;
