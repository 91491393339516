import Cookies from "js-cookie";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext/AuthContext";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import api from "../../../services/api";
import { LogoutButton, LogoutDiv, LogoutIcon } from "./styles";

function Logout() {

  const history = useHistory();

  const { auth, setAuth } = useContext(AuthContext);

  const { setUser } = useContext(GeneralContext);

  useEffect(() => {
    api("user", {
      method: "GET",
    }).then(async (res) => {
      if (res.status >= 200 && res.status < 300) {
        const data = await res.json();
        setUser(data.response);
      }
    });
  }, [setUser]);

  useEffect(() => {
    if (auth === false) {
      history.push("/");
    }
  }, [auth, history]);

  const logout = () => {
    Cookies.remove("@user");
    setAuth(false);
    api("logout", {
      method: "GET"
    })
  };

  return (
    <div>
      <LogoutDiv onClick={logout}>
        <LogoutIcon />
        <LogoutButton>Sair</LogoutButton>
      </LogoutDiv>
    </div>
  );
}

export default Logout;
