import styled from "styled-components";

export const Select = styled.select``;

export const InputContainer = styled.div`
  select {
    width: 100%;
    padding: 10px;
    border: none;
    margin-top: 3px;
    background: ${(props) => props.theme.color.input.background};
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.color.input.stroke};
    color: ${(props) => props.theme.color.text};
  }
  :first-child {
    background: red;
  }
`;
