import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import { Form } from "@unform/core";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import api from "../../../services/api";
import Button from "../../atoms/Button";
import RoundedInput from "../../atoms/RoundedInput";
import TagBox from "../TagBox";
import {
  AddBoxButtonsHolder,
  AddContainer,
  AddHolder,
  AddSectionHeader,
  AddSectionTitle,
  GrowContainer,
} from "./styles";

function AddSection({ setRefresh,addPage }) {

  const [checked, setChecked] = useState(false);
  const [words, setWords] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const formRef = useRef(null);

  const handle_check = () => {
    setChecked(true);
  };

  const handle_clickaway = () => {
    setChecked(false);
  };

  const submit_form = async () => {
    formRef.current.submitForm();
  };

  const handle_submit = (args, { reset }) => {
    let tags = args.tag.split(",");
    let strippedTags = tags
      .map((item) => item.trim().toLowerCase())
      .filter((item) => item !== "");
    if (strippedTags.length > 0) {
      setWords((prevState) => prevState.concat(strippedTags));
    }
    reset();
  };

  const handle_add = () => {
    if(addPage == "keywords"){
      api("terms", {
        method: "POST",
        body: JSON.stringify({ words }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(async (res) => {
        if (res.status >= 200 && res.status < 300) {
          const data = await res.json();
          enqueueSnackbar(data.response, { variant: "success" });
          setWords([]);
          setRefresh(Math.random());
        } else {
          const data = await res.json();
          enqueueSnackbar(data.response, { variant: "error" });
        }
      });
    }
    else if (addPage == "blacklist"){
      let id = words
      console.log("id" + id)
      api("setIdToBl", {
        method: "PUT",
        body: JSON.stringify({ id }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then(async (res) => {
        if (res.status >= 200 && res.status < 300) {
          const data = await res.json();
          enqueueSnackbar(data.response, { variant: "success" });
          setWords([]);
          setRefresh(Math.random());
        } else {
          const data = await res.json();
          enqueueSnackbar(data.response, { variant: "error" });
        }
      });

    }
   
  };

  return (
    <ClickAwayListener onClickAway={handle_clickaway}>
      <AddContainer>
        <Button width="150px" margin="0 .5rem 0 .5rem" onClick={handle_check}>
          Adicionar
        </Button>

        <Grow
          in={checked}
          style={{ transformOrigin: "500px 0 0" }}
          {...(checked ? { timeout: 200 } : {})}
        >
          <GrowContainer>
            <AddHolder>
              <AddSectionHeader>
                <AddSectionTitle>Adicionar</AddSectionTitle>
              </AddSectionHeader>
              <TagBox words={words} setWords={setWords} />
              <Form ref={formRef} onSubmit={handle_submit}>
                <RoundedInput
                  name="tag"
                  required
                  placeholder="Escreva palavras separadas por vírgula para adicionar mais palavras de uma só vez!"
                />
              </Form>
              <AddBoxButtonsHolder>
                <Button margin="10px 5px" onClick={submit_form}>
                  Pré-visualização
                </Button>
                {words.length > 0 ? (
                  <Button margin="10px 5px" onClick={handle_add}>
                    Adicionar
                  </Button>
                ) : null}
              </AddBoxButtonsHolder>
            </AddHolder>
          </GrowContainer>
        </Grow>
      </AddContainer>
    </ClickAwayListener>
  );
}

export default AddSection;
