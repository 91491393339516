import React from 'react';
import Paging from '@material-ui/lab/Pagination';

//styles
import { PaginationContainer } from './styles';

export default function Pagination({ totalPages, page, setPage }) {

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <PaginationContainer>
            <Paging count={totalPages} page={page} onChange={handleChange} />
        </PaginationContainer>
    );
}