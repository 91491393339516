import styled from "styled-components";

export const CardContainer = styled.div`
  background: ${(props) => props.theme.color.primary};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  min-height: ${(props) => props.minHeight};
  border: 1px solid ${(props) => props.theme.color.stroke};
  margin: 0.4rem 0.4rem;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 3px 5px #0000001d;
  @media (max-width: 705px) {
    overflow-x: ${(props) => props.overflow};
  }
`;
