import styled from "styled-components";

export const InputContainer = styled.div`
  input {
    width: 100%;
    padding: 10px;
    height: 40px;
    border: none;
    margin-top: 3px;
    background: ${(props) => props.theme.color.input.background};
    border: 1px solid ${(props) => props.theme.color.input.stroke};
    color: ${(props) => props.theme.color.input.text};
    border-radius: 10px;
    :placeholder {
      ${(props) => props.theme.color.input.placeholder};
    }
  }
`;
