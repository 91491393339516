export default {
  color: {
    background: "#FAFBFC",
    secondary: "#0073EA",
    primary: "#F6F7F8",
    hover: "#DDDEDF",
    stroke: "#CCC",
    text: "#2C3135",
    icon: "#818386",
    buttons: {
      background: "#0073EA",
      hover: "#00468E",
      text: "#FAFBFC",
      accept: "#548FFF",
      disabled: "#80B9F5",
    },
    input: {
      background: "#F0F2F5",
      stroke: "#CCC",
      text: "#2C3135",
      disable: "#E2E2E2",
      focused: "#0073EA",
      textdisabled: "#626262",
      placeholder: "#7D838C",
      redalert: "#F32C40",
      greenalert: "#65A84E",
    },
  },
  login: {
    background: "#FAFBFC",
    text: "#3F484F",
    stroke: "#CCC",
    input: {
      background: "#F0F2F5",
      stroke: "#CCC",
      text: "#2C3135",
      disable: "#E2E2E2",
      focused: "#0073EA",
      textdisabled: "#626262",
      placeholder: "#7D838C",
      redalert: "#F32C40",
      greenalert: "#65A84E",
    },
  },
  usermenu: {
    background: "#F6F7F8",
    stroke: "#CCC",
    text: "#2C3135",
    hover: "#DDDEDF",
    icon: "#818386",
    highlight: "#83A1EA",
    selected: "#0073EA",
  },
  switch: {
    on: "#0073EA",
    circleOn: "#FAFBFC",
    stroke: "#CCC",
    off: "#FAFBFC",
    circleOff: "#818386",
  },
};
