export default {
  color: {
    background: "#2A2D30",
    secondary: "#0073EA",
    primary: "#2C3135",
    hover: "#45525B",
    stroke: "#3F484F",
    text: "#FAFBFC",
    icon: "#FAFBFC",
    buttons: {
      background: "#0073EA",
      hover: "#00468E",
      text: "#FAFBFC",
      accept: "#548FFF",
      disabled: "#80B9F5",
    },
    input: {
      background: "#45525B",
      stroke: "#3F484F",
      text: "#FAFBFC",
      disable: "#818181",
      focused: "#0073EA",
      textdisabled: "#626262",
      placeholder: "#7D838C",
      redalert: "#F32C40",
      greenalert: "#65A84E",
    },
  },
  login: {
    background: "#FAFBFC",
    text: "#3F484F",
    stroke: "#CCC",
    input: {
      background: "#F0F2F5",
      stroke: "#CCC",
      text: "#2C3135",
      disable: "#E2E2E2",
      focused: "#0073EA",
      textdisabled: "#626262",
      placeholder: "#7D838C",
      redalert: "#F32C40",
      greenalert: "#65A84E",
    },
  },
  usermenu: {
    background: "#2C3135",
    stroke: "#3F484F",
    text: "#FAFBFC",
    hover: "#45525B",
    icon: "#FAFBFC",
    highlight: "#83A1EA",
    selected: "#0073EA",
  },
  switch: {
    on: "#0073EA",
    circleOn: "#FAFBFC",
    stroke: "#CCC",
    off: "#45525B",
    circleOff: "#818386",
  },
};
