import React, { useContext } from "react";
import Chart from "react-apexcharts";
import ThemeContext from "../../../contexts/ThemeContext/ThemeContext";
import { PieContainer } from "./styles";

function PieChart({ name, dados }) {
  const { theme } = useContext(ThemeContext);

  return (
    <PieContainer>
      <Chart
        options={{
          chart: {
            type: "pie",
          },
          theme: {
            monochrome: {
              enabled: true,
            },
          },
          title: {
            text: name,
            style: {
              color: theme.color.text,
            },
          },
          labels: !!dados ? Object.keys(dados) : [],
          legend: {
            position: "bottom",
            labels: {
              colors: theme.color.text,
              useSeriesColors: false,
            },
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  width: "100%",
                  height: 250,
                },
                legend: {
                  show: true,
                },
              },
            },
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: "100%",
                },
                legend: {
                  position: "bottom",
                  labels: {
                    colors: theme.color.text,
                    useSeriesColors: false,
                  },
                },
              },
            },
          ],
        }}
        height={550}
        series={!!dados ? Object.values(dados) : []}
        type="pie"
      />
    </PieContainer>
  );
}

export default PieChart;
