import { GearFill } from "@styled-icons/bootstrap/GearFill";
import { KeyFill } from "@styled-icons/bootstrap/KeyFill";
import { SafeFill } from "@styled-icons/bootstrap/SafeFill";
import { BarGraph } from "@styled-icons/entypo/BarGraph";
import { Close } from "@styled-icons/evaicons-solid/Close";
import { GreaterThan } from "@styled-icons/fa-solid/GreaterThan";
import { UsersSlash } from "@styled-icons/fa-solid/UsersSlash";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { UserSlash } from "styled-icons/fa-solid";

export const BlacklistIcon = styled(UsersSlash)`
  width: 2rem;
  min-width: 25px;
`;


export const CloseIcon = styled(Close)`
  color: ${(props) => props.theme.color.background};
  width: 15px;
`;

export const LinkDiv = styled(Link)`
  width: 85%;
  color: ${(props) => props.theme.color.text};
  :hover {
    background: ${(props) => props.theme.color.hover};
    border-radius: 10px;
  }
  .Active {
    background: ${(props) => props.theme.usermenu.highlight};
    color: ${(props) => props.theme.usermenu.selected};
    border-radius: 10px;
  }
`;

export const UserDiv = styled.div`
  width: 85%;
  :hover {
    background: ${(props) => props.theme.color.hover};
    border-radius: 10px;
  }
`;

export const Arrow = styled(GreaterThan)`
  color: ${(props) => props.theme.color.background};
  width: 10px;
`;

export const SettingsIcon = styled(GearFill)`
  width: 2rem;
  min-width: 25px;
`;

export const GraphIcon = styled(BarGraph)`
  width: 2rem;
  min-width: 25px;
`;

export const KeyIcon = styled(KeyFill)`
  width: 2rem;
  min-width: 25px;
`;

export const HomeIcon = styled(SafeFill)`
  width: 2rem;
  min-width: 25px;
`;

export const AsideContainer = styled.aside`
  width: ${(props) => (props.openmenu ? `30rem` : `3rem`)};
  min-width: 50px;
  display: flex;
  height: 100%;
  flex-direction: column;
  color: ${(props) => props.theme.color.text};
  background: ${(props) => props.theme.color.primary};
  border-right: 1px solid ${(props) => props.theme.color.stroke};
  justify-content: space-between;
  font-size: 1.5rem;
  padding: 5px 0px 10px 0px;
  transition: 100ms ease;

  .AUList {
    align-items: center;
  }
  .AsideText {
    display: ${(props) => (props.openmenu ? `inline-block` : `none`)};
  }
`;

export const AsideUnorderedList = styled.ul`
  display: flex;
  flex-direction: column;
  style-type: none;
  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const AsideLink = styled.li`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: ${(props) => (props.openmenu ? `10px` : `0px`)};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.openmenu ? `start` : `center`)};
  align-self: center;
  cursor: pointer;
  transition: 100ms ease-in;

  button {
    font-size: 15px;
  }
`;

export const AsideUnorderedListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:1000;
  width: 20px;
  height: 20px;
  background: ${(props) => props.theme.color.text};
  border-radius: 100%;
  position: relative;
  margin-left: ${(props) => (props.openmenu ? `100%` : `94%`)};
  transform: ${(props) => (props.openmenu ? `rotate(180deg)` : `rotate(0deg)`)};
  transition: 300ms ease;
  border: 1px solid ${(props) => props.theme.color.stroke};
`;

export const AsideText = styled.div`
  white-space: nowrap;
  margin-left: 10px;
`;
