import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.login.background};
`;

export const Logo = styled.nav`
  width: 100%;

  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;

  svg {
    fill: ${(props) => props.theme.login.text};
  }

  svg:hover {
    fill: #ec7408;
    cursor: pointer;
  }
`;

export const WelcomeTitle = styled.h1`
  font-weight: 300;
  font-size: 1.2rem;
  font-size: 2.5rem;
`;

export const MiddleContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;
