import Cookie from "js-cookie";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext/AuthContext";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import api from "../../../services/api";
import Button from "../../atoms/Button";
//import { Form } from "@unform/web";
import Input from "../../atoms/LoginInput";
import {
  SafeIcon,
  Divider,
  Form,
  LoginContainer,
  LoginTitle,
  ProductName,
  ProductNameContainer,
} from "./styles";

function LoginBox() {
  const { setLoading } = useContext(GeneralContext);
  const { auth, setAuth } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  useEffect(() => {
    if (auth === true) history.push("/home");
  }, [auth, history]);

  const handle_login = ({ username, password }) => {
    setLoading(true);

    api("login", {
      method: "GET",
      headers: { user: JSON.stringify({ username, password }) },
    }).then(async (res) => {
      if (res.status >= 200 && res.status < 300) {
        Cookie.set("@user", 'login', {
          expires: new Date(60*60*1000),
          secure: true
        });
        setLoading(false);
        setAuth(true);
      } else {
        setLoading(false);
        enqueueSnackbar("Usuário ou senha incorreto.", { variant: "error" });
      }
    });
  };

  return (
    <LoginContainer>
      <ProductNameContainer>
        <SafeIcon />
        <ProductName>
          DATA<br></br> LOSS
          <br></br> PREVENTION
        </ProductName>
      </ProductNameContainer>
      <Divider></Divider>
      <LoginTitle>Login</LoginTitle>
      <Form onSubmit={handle_login}>
        <Input
          name="username"
          type="text"
          placeholder="Usuário"
          width={"100%"}
        />
        <Input
          name="password"
          type="password"
          placeholder="Senha"
          width={"100%"}
        />
        <Button width={"100%"}>Entrar</Button>
      </Form>
    </LoginContainer>
  );
}

export default LoginBox;
