import React, { useContext } from "react";
import Chart from "react-apexcharts";
import ThemeContext from "../../../contexts/ThemeContext/ThemeContext";
import { BarContainer } from "./styles";

function BarChart({ name, dados }) {
  const { theme } = useContext(ThemeContext);

  return (
    <BarContainer>
      <Chart
        options={{
          chart: {
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: !!dados ? Object.keys(dados) : [],
            rotate: 0,
            labels: {
              maxHeight: 50,
              style: {
                colors: theme.color.text,
                fontSize: "1.2em",
              },
            },
          },
          yaxis: {
            labels: {
              rotate: 0,
              style: {
                colors: theme.color.text,
              },
            },
          },
          legend: {
            show: false,
          },
          grid: {
            borderColor: "#e0e0e01d",
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },
                xaxis: {
                  labels: {
                    maxHeight: 50,
                    style: {
                      colors: theme.color.text,
                      fontSize: "1em",
                    },
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        }}
        series={[
          { name: "Infrações", data: !!dados ? Object.values(dados) : [] },
        ]}
        type="bar"
      />
    </BarContainer>
  );
}

export default BarChart;
