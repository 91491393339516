import Cookies from 'js-cookie';
import { appCheck } from './firebase';
import { getToken } from '@firebase/app-check';

var baseURL = ""

if(process.env.REACT_APP_MODE === 'dev'){
    baseURL = `http://localhost:8080/backend/`
}else{
    baseURL = `https://${process.env.REACT_APP_CLIENT_URL}/backend/`
}

const api = async (endpoint, ...params) => {
    const appCheckToken = await getToken(appCheck);
    if (!params[0].headers){
        params[0]['headers'] = {
            'X-Firebase-AppCheck': appCheckToken.token
        }
    }else {
        params[0].headers['X-Firebase-AppCheck'] = appCheckToken.token;
    }
    params[0]['credentials'] = 'include'
    const pre_request = await fetch(`${baseURL}${endpoint}`, ...params);
    if(pre_request.status === 401 && pre_request.url.split('/')[4] !== 'login'){
        Cookies.remove('@user');
        window.location.href = '/';
    }
    return pre_request;
}

export default api;
