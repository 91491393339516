import React, { useState } from "react";
import * as Themes from "../../static/themes";
import ThemeContext from "./ThemeContext";

function ThemeContextProvider({ children }) {
  const [theme, setTheme] = useState(Themes.dark);

  const toggleTheme = () => {
    if (theme === Themes.dark) {
      setTheme(Themes.light);
    } else if (theme === Themes.light) {
      setTheme(Themes.dark);
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export default ThemeContextProvider;
