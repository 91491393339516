import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
//contexts
import { ThemeProvider } from "styled-components";
import AuthContext from "../contexts/AuthContext/AuthContext";
import GeneralContextProvider from "../contexts/GeneralContext/GeneralContextProvider";
import ThemeContext from "../contexts/ThemeContext/ThemeContext";
//pages
import LoginPage from "../pages/Login";
import PanelPage from "../pages/Panel";
import "../services/config.js";
//subPages
import HomeContent from "../sub_pages/Home";
import KeywordsContent from "../sub_pages/Keywords";
import BlacklistContent from "../sub_pages/Blacklist";
import ReportContent from "../sub_pages/Report";
import SettingsContent from "../sub_pages/Settings";

export default function routes() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { auth } = useContext(AuthContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { theme } = useContext(ThemeContext);

  const ProtectedRoute = ({
    component: Component,
    path: Path,
    content,
    ...rest
  }) => {
    return (
      <Route
        Path
        render={(props) =>
          auth === true ? (
            <GeneralContextProvider>
              <ThemeProvider theme={theme}>
                <Component Content={content} {...props} {...rest} />
              </ThemeProvider>
            </GeneralContextProvider>
          ) : (
            <Redirect to={"/"} />
          )
        }
      />
    );
  };

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <GeneralContextProvider>
            <ThemeProvider theme={theme}>
              <LoginPage />
            </ThemeProvider>
          </GeneralContextProvider>
        </Route>

        <ProtectedRoute
          path="/home"
          component={PanelPage}
          content={HomeContent}
        />
        <ProtectedRoute
          path="/keywords"
          component={PanelPage}
          content={KeywordsContent}
        />
        <ProtectedRoute
          path="/blacklist"
          component={PanelPage}
          content={BlacklistContent}
        />
        <ProtectedRoute
          path="/report"
          component={PanelPage}
          content={ReportContent}
        />
        <ProtectedRoute
          path="/settings"
          component={PanelPage}
          content={SettingsContent}
        />
      </Switch>
    </Router>
  );
}
