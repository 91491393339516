import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import BurgerButton from "../../atoms/BurgerButton";
import UserMenuPopover from "../../organism/UserMenuPopover";
import {
  Arrow,
  AsideContainer,
  AsideLink,
  AsideText,
  AsideUnorderedList,
  AsideUnorderedListHeader,
  CloseIcon,
  GraphIcon,
  HomeIcon,
  KeyIcon,
  LinkDiv,
  SettingsIcon,
  UserDiv,
  BlacklistIcon,
} from "./styles";

function Aside() {
  const [openmenu, setOpenMenu] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [renderAside, setRenderAside] = useState(true);

  function toggleMenu() {
    setOpenMenu(!openmenu);
  }
  const ql = window.matchMedia("(min-width: 415px)").matches;
  function toggleRenderAside() {
    setRenderAside(!renderAside);
    setOpenMenu(false);
  }

  return (
    <div>
      {renderAside ? (
        <AsideContainer id="mySidenav" openmenu={openmenu}>
          <div>
            <AsideUnorderedList className="AUList">
              {ql ? (
                <AsideUnorderedListHeader
                  onClick={toggleMenu}
                  openmenu={openmenu}
                >
                  <Arrow />
                </AsideUnorderedListHeader>
              ) : (
                <AsideUnorderedListHeader onClick={toggleRenderAside}>
                  <CloseIcon />
                </AsideUnorderedListHeader>
              )}
              <LinkDiv to="/home">
                <AsideLink
                  openmenu={openmenu}
                  className={splitLocation[1] === "home" ? "Active" : ""}
                >
                  <HomeIcon />
                  <AsideText className="AsideText">Início</AsideText>
                </AsideLink>
              </LinkDiv>
              <LinkDiv to="/keywords">
                <AsideLink
                  openmenu={openmenu}
                  className={splitLocation[1] === "keywords" ? "Active" : ""}
                >
                  <KeyIcon />
                  <AsideText className="AsideText">Palavras-chave</AsideText>
                </AsideLink>
              </LinkDiv>
              <LinkDiv to="/report">
                <AsideLink
                  openmenu={openmenu}
                  className={splitLocation[1] === "report" ? "Active" : ""}
                >
                  <GraphIcon />
                  <AsideText className="AsideText">Relatórios</AsideText>
                </AsideLink>
              </LinkDiv>
              <LinkDiv to="/blacklist">
                <AsideLink
                  openmenu={openmenu}
                  className={splitLocation[1] === "blacklist" ? "Active" : ""}
                >
                  <BlacklistIcon />
                  <AsideText className="AsideText">BlackList</AsideText>
                </AsideLink>
              </LinkDiv>
              <LinkDiv to="/settings">
                <AsideLink
                  openmenu={openmenu}
                  className={splitLocation[1] === "settings" ? "Active" : ""}
                >
                  <SettingsIcon />
                  <AsideText className="AsideText">Configurações</AsideText>
                </AsideLink>
              </LinkDiv>
            </AsideUnorderedList>
          </div>
          <AsideUnorderedList className="AUList">
            <UserDiv>
              <AsideLink openmenu={openmenu}>
                <UserMenuPopover></UserMenuPopover>
              </AsideLink>
            </UserDiv>
          </AsideUnorderedList>
        </AsideContainer>
      ) : (
        <BurgerButton onClick={toggleRenderAside}></BurgerButton>
      )}
    </div>
  );
}

export default Aside;
