import { AccountLogout } from "@styled-icons/open-iconic/AccountLogout";
import styled from "styled-components";

export const LogoutIcon = styled(AccountLogout)`
  color: ${(props) => props.theme.usermenu.icon};
  width: 1.5em;
  margin-right: 1em;
  margin-left: 0.5em;
`;

export const LogoutDiv = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -1.3em;
  border-radius: 5px;
  background: rgba(1, 1, 1, 0);
  width: 12vw;
  height: 40%;
`;

export const LogoutButton = styled.div``;
