import React, { useContext, useState } from "react";
import GeneralContext from "../../../contexts/GeneralContext/GeneralContext";
import {
  AsideContainer,
  GroupAddIcon,
  ListLink,
  PersonAddIcon,
  PersonIcon,
  UnorderedList,
} from "./styles";

function SettingsAside({ setPage }) {
  const { user } = useContext(GeneralContext);
  const [pages, setPages] = useState("profile");
  return (
    <AsideContainer>
      <UnorderedList>
        <ListLink
          onClick={() => {
            setPage("profile");
            setPages("profile");
          }}
          className={pages === "profile" ? "Active" : ""}
        >
          <PersonIcon fontSize="inherit" />
          Perfil
        </ListLink>
        {user.role !== "viewer" ? (
          <>
            <ListLink
              onClick={() => {
                setPage("addUser");
                setPages("addUser");
              }}
              className={pages === "addUser" ? "Active" : ""}
            >
              <GroupAddIcon fontSize="inherit" />
              Adicionar usuários
            </ListLink>
            <ListLink
              onClick={() => {
                setPage("manageUser");
                setPages("manageUser");
              }}
              className={pages === "manageUser" ? "Active" : ""}
            >
              <PersonAddIcon fontSize="inherit" />
              Gerenciar usuários
            </ListLink>
            <ListLink></ListLink>
          </>
        ) : null}
      </UnorderedList>
    </AsideContainer>
  );
}

export default SettingsAside;
