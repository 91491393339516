import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/atoms/Header";
import Table from "../../components/organism/KeywordTable";
import GeneralContext from "../../contexts/GeneralContext/GeneralContext";
//services
import api from "../../services/api";
import { KeywordsContainer, KeywordsContent } from "./styles";

function Keywords() {
  const [tableRows, setTableRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [query, setQuery] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading, user } = useContext(GeneralContext);


  const tableHeader =
    user.role !== "viewer"
      ? ["Índice", "Palavra", "Ações"]
      : ["Índice", "Palavra"];

  useEffect(() => {
    setLoading(true);

    api(`terms?page=${page}`, {
      method: "GET",
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setTableRows(data.rows);
        setTotalPages(data.totalPages);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [page, setLoading, refresh]);

  useEffect(() => {
    let filtered = tableRows.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );

    if (filtered.length === 0 && tableRows.length > 0) {
      api(`search/terms?query=${query}`, {
        method: "GET",
      }).then(async (res) => {
        const data = await res.json();
        if (res.status >= 200 && res.status < 300) {
          setTableRows([data.term]);
        } else {
          enqueueSnackbar(data.response, { variant: "error" });
        }
      });
    } else {
      setTableRows(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, query]);

  const handle_delete = async (term) => {
    api(`terms`, {
      method: "DELETE",
      body: JSON.stringify({ term }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        enqueueSnackbar(data.response, { variant: "success" });
        setRefresh(Math.random());
      } else {
        enqueueSnackbar(data.response, { variant: "error" });
      }
    });
  };

  return (
    <KeywordsContainer>
      <Header>
        <strong>DLP</strong>&nbsp;|&nbsp;Palavras-chave
      </Header>
      <KeywordsContent>
        <Table
          tableHeader={tableHeader}
          tableRows={tableRows}
          totalPages={totalPages}
          page={page}
          setPage={setPage}
          setRefresh={setRefresh}
          addPage={"keywords"}
          handle_delete={handle_delete}
          setQuery={setQuery}
          edit={true}
        />
      </KeywordsContent>
    </KeywordsContainer>
  );
}

export default Keywords;
