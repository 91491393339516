import React, { useContext } from "react";
import Switch from "react-switch";
import ThemeContext from "../../../contexts/ThemeContext/ThemeContext";
import { SwitchStroke } from "./styles";

function SwitchButton({ checked, onChange }) {
  const { theme } = useContext(ThemeContext);

  var offColor = theme.switch.off;
  var onColor = theme.switch.on;
  var onHandleColor = theme.switch.circleOn;
  var offHandleColor = theme.switch.circleOff;

  return (
    <SwitchStroke>
      <Switch
        offColor={offColor}
        onColor={onColor}
        onHandleColor={onHandleColor}
        offHandleColor={offHandleColor}
        checked={checked}
        checkedIcon={false}
        uncheckedIcon={false}
        onChange={onChange}
        height={18}
        width={36}
      />
    </SwitchStroke>
  );
}

export default SwitchButton;
