import styled from "styled-components";

export const EditContainer = styled.div`
  position: relative;
`;

export const EditHolder = styled.div`
  width: 25rem;
  background: ${(props) => props.theme.color.background};
  box-shadow: 0 2px 5px #0000001d;
  margin-top: 1rem;
  position: absolute;
  padding: 10px;
  right: 1rem;
  border-radius: 10px;
  z-index: 12;

  form {
    display: flex;
    align-items: center;
  }
`;
