import styled from "styled-components";

export const ProfileInformation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: ${(props) => props.theme.color.background};
  padding: 0.5rem 1rem;
  border-radius: 5px;

  form {
    width: 25%;
    font-size: 1.4rem;
    @media (max-width: 415px) {
      width: 100%;
    }
  }
`;

export const ProfilePhotoHolder = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background: ${(props) => props.theme.color.background};
  overflow: hidden;
  border: 2px solid ${(props) => props.theme.color.stroke};
`;

export const ProfilePhotoContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProfilePhoto = styled.img`
  width: 100%;
  height: 100%;
`;

export const InformationContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.color.stroke};
`;

export const InformationTitle = styled.h2`
  color: ${(props) => props.theme.color.text};
  font-size: inherit;
  margin-bottom: 0.5rem;
`;

export const InformationSection = styled.div`
  margin: 2rem 0;
`;

export const ChangeContainer = styled.div`
  padding: 1rem 0;
`;

export const ChangeHolder = styled.div``;
