import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import RoundedButton from '../../atoms/TableRoundedButton';
import RoundedInput from '../../atoms/RoundedInput';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { Form } from '@unform/web';

import { EditContainer, EditHolder } from './styles';
import api from '../../../services/api';

function EditSection({ setRefresh, rowData }) {

    const [ checked, setChecked ] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handle_check = () => {
        setChecked(true);
    }
    
    const handle_clickaway = () => {
        setChecked(false);
    }

    const handle_submit = async ({ changed_term }, { reset }) => {
        await api('terms', {
            method: 'PUT',
            body: JSON.stringify({ old: rowData, changed: changed_term }),
            headers: { 
                'Content-Type': 'application/json' 
            }
        }).then(async res => {
            const data = await res.json()
            if(res.status >= 200 && res.status < 300){
                enqueueSnackbar(data.response, { variant: "success" })
                setRefresh(Math.random())
                reset();
            }else{
                enqueueSnackbar(data.response, { variant: "error" })
            }
        });
    }

    return(
        <ClickAwayListener onClickAway={handle_clickaway}>
            <EditContainer>
                <RoundedButton onClick={handle_check}><EditIcon /></RoundedButton>
                <Grow in={checked} style={{ transformOrigin: '500px 0 0' }} {...(checked ? { timeout: 200 } : {})}>
                    <EditHolder>
                        <Form onSubmit={handle_submit}>
                            <RoundedInput name='changed_term'/><RoundedButton><SaveIcon /></RoundedButton>
                        </Form>
                    </EditHolder>
                </Grow>
            </EditContainer>
        </ClickAwayListener>
    )
}

export default EditSection;