import { Send } from "@styled-icons/fluentui-system-filled/Send";
import styled from "styled-components";

export const TableFilterContainer = styled.div`
  display: flex;
  margin: 0px;
`;

export const SendIcon = styled(Send)`
  color: white;
  width: 80%;
`;

export const SendButton = styled.button`
  display: flex;
  width: 30px;
  height: 40px;
  border-radius: 0px 8px 8px 0px;
  background: ${(props) => props.theme.color.secondary};
  border: 1px solid ${(props) => props.theme.color.secondary};
  margin-left: -15px;
  margin-right: 1.3rem;
`;
