import styled from "styled-components";

export const InputContainer = styled.div`
  input {
    padding: 10px;
    border: none;
    color: ${(props) => props.theme.color.input.text};
    background: ${(props) => props.theme.color.input.background};
    border-radius: 20px;
    width: 100%;
    border: 1px solid #3333331d;
    ::placeholder {
      color: ${(props) => props.theme.color.input.placeholder};
    }
  }
`;
