import styled from "styled-components";

export const InputContainer = styled.div`
  input {
    background-color: ${(props) => props.theme.login.input.background};
    auto-align: center;
    height: 40px;
    padding: 10px;
    margin-bottom: 2rem;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.login.input.stroke};
    height: 40px;
     {
      ::placeholder {
        color: ${(props) => props.theme.login.input.placeholder};
      }
      :focus {
        color: ${(props) => props.theme.login.text};
        outline: 1px solid ${(props) => props.theme.login.input.focused};
      }
    }
  }
`;
