import styled from "styled-components";

export const HeaderComponent = styled.h1`
  font-size: 2rem;
  height: 90px;
  position:fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 50px;
  font-weight: 300;
  margin-bottom: 5rem;
  color: ${(props) => props.theme.color.text};
  border-bottom: 1px solid ${(props) => props.theme.color.stroke};
  background-color: ${(props) => props.theme.color.background};
  @media (min-width: 415px) {
    font-size: 3rem;
  }
`;
