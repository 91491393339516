import { useField } from "@unform/core";
import React, { useEffect, useRef } from "react";
import { InputContainer, Select } from "./styles";
export default function SelectInput({ name, width, children, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <InputContainer>
      <Select style={{ width }} ref={inputRef} {...rest}>
        {children}
      </Select>
    </InputContainer>
  );
}
