import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import BarChart from "../../components/atoms/BarChart";
import BubbleChart from "../../components/atoms/BubbleChart";
import Button from "../../components/atoms/Button";
import Card from "../../components/atoms/Card";
import DatePicker from "../../components/molecule/DateRange";
import Header from "../../components/atoms/Header";
import NamePicker from "../../components/molecule/NamePicker";
import PieChart from "../../components/atoms/PieChart";
import ReportTable from "../../components/organism/ReportTable";
import TimeChart from "../../components/atoms/TimeChart";
import GeneralContext from "../../contexts/GeneralContext/GeneralContext";
import api from "../../services/api";
import {
  CardContent,
  CardTitle,
  ChartHolder,
  ChartRow,
  ReportContainer,
  ReportHeader,
  ReportHolder,
  TableData,
  TableRow,
} from "./styles";

function Report() {
  const { setLoading } = useContext(GeneralContext);

  const [fastRefresh, setFastRefresh] = useState();
  const [infrationContent, setInfrationContent] = useState([]);
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(0);
  const [bubbleData, setBubbleData] = useState([]);
  const [localData, setLocalData] = useState({});
  const [topData, setTopData] = useState({});
  const [totalsData, setTotalsData] = useState({});
  const [timeData, setTimeData] = useState({});

  const numberCardWidth = "100%";
  //const numberCardWidth = "19.5%";

  const [query, setQuery] = useState({});

  useEffect(() => {
    setLoading(true);
    // setPageTokens({ 1: undefined });
    api(`report/totalPerUser`, {
      method: "GET",
      headers: {
        Query: JSON.stringify(query),
      },
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setBubbleData(data.response);
      }
    });
    api(`report/totalPerType`, {
      method: "GET",
      headers: {
        Query: JSON.stringify(query),
      },
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setTotalsData(data.response[0]);
      }
    });
    api(`report/table`, {
      method: "GET",
      headers: {
        Query: JSON.stringify(query),
        Offset: page
      },
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setInfrationContent(data.response.rows);
        setResults(data.response.results);
        const key = page + 1;
      }
    });
    api(`report/getTimeEvents`, {
      method: "GET",
      headers: {
        Query: JSON.stringify(query),
      },
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setTimeData(data.response);
      }
    });
    api(`report/totalPerLocal`, {
      method: "GET",
      headers: {
        Query: JSON.stringify(query),
      },
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setLocalData(data.response[0]);
      }
    });
    api(`report/topUser`, {
      method: "GET",
      headers: {
        Query: JSON.stringify(query),
      },
    }).then(async (res) => {
      const data = await res.json();
      if (res.status >= 200 && res.status < 300) {
        setTopData(data.response);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fastRefresh, setLoading, query, page]);

  const totalViolation =
    totalsData.badword +
    totalsData.cpf +
    totalsData.rg +
    totalsData.credit_card +
    totalsData.adult_content;

  const TableRows = () => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return (
      <>
        {infrationContent.length > 0
          ? infrationContent.map((item, index) => (
              <TableRow key={index}>
                <TableData>
                  {new Date(item.created_time.value).toLocaleDateString(
                    "pt-BR",
                    options
                  )}
                </TableData>
                <TableData>{item.user_name}</TableData>
                <TableData title={item.content}>
                  <span>{item.content}</span>
                </TableData>
                <TableData>{item.violation_quote}</TableData>
                <TableData>{item.file_type}</TableData>
                <TableData>{item.violation_type}</TableData>
                <TableData>{item.local}</TableData>
                <TableData>{item.image == "Sem Imagem" || item.image == null ? 
                  "Sem imagem"
                :
                  <a href={item.image}>Imagem</a>
                }</TableData>
              </TableRow>
            ))
          : null}
      </>
    );
  };

  return (
    <ReportContainer>
      <Header>
        <strong>DLP</strong>&nbsp;|&nbsp;Relatórios
        <IconButton
          color="inherit"
          size="small"
          onClick={() => setFastRefresh(Math.random())}
        >
          <RefreshIcon fontSize="large" />
        </IconButton>
      </Header>
      <ReportHolder>
        <ReportHeader>
          <DatePicker query={query} setQuery={setQuery} />
          <NamePicker query={query} setQuery={setQuery} />
          <Button
            width="150px"
            margin="0 .5rem 0 .5rem"
            onClick={() => setQuery([])}
          >
            Limpar Filtro
          </Button>
        </ReportHeader>
        <ChartHolder>
          <ChartRow>
            <Card width={numberCardWidth} minWidth={"100px"}>
              <CardTitle>Palavras-chave bloqueadas</CardTitle>
              <CardContent>{totalsData.badword || 0}</CardContent>
            </Card>
            <Card width={numberCardWidth} minWidth={"100px"}>
              <CardTitle>CPFs bloqueados</CardTitle>
              <CardContent>{totalsData.cpf || 0}</CardContent>
            </Card>
            <Card width={numberCardWidth} minWidth={"100px"}>
              <CardTitle>RGs bloqueados</CardTitle>
              <CardContent>{totalsData.rg || 0}</CardContent>
            </Card>
            <Card width={numberCardWidth} minWidth={"100px"}>
              <CardTitle>Cartões bloqueados</CardTitle>
              <CardContent>{totalsData.credit_card || 0}</CardContent>
            </Card>
            <Card width={numberCardWidth} minWidth={"100px"}>
              <CardTitle>Conteúdos adulto bloqueados</CardTitle>
              <CardContent>{totalsData.adult_content || 0}</CardContent>
            </Card>
            <Card width={numberCardWidth} minWidth={"100px"}>
              <CardTitle>Total de violações</CardTitle>
              <CardContent>{totalViolation || 0}</CardContent>
            </Card>
          </ChartRow>
          <ChartRow>
            <Card width={"100%"} minWidth={"250px"}>
              <CardTitle>Infrações temporais</CardTitle>
              <TimeChart data={timeData} name="Infrações temporais" />
            </Card>
            <Card width={"100%"} minWidth={"250px"}>
              <CardTitle>Top 5 maiores infratores da comunidade</CardTitle>
              <BarChart
                dados={topData}
                name="Top 5 maiores infratores da comunidade"
              />
            </Card>
          </ChartRow>
          <ChartRow>
            <Card width={"100%"} minWidth={"250px"} overflow={"scroll"}>
              <ReportTable
                tableHeader={[
                  "Data",
                  "Usuário",
                  "Conteúdo",
                  "Infração",
                  "Tipo do arquivo",
                  "Tipo de infração",
                  "Local",
                  "Imagem"
                ]}
                TableRows={TableRows}
                page={page}
                setPage={setPage}
                results={results}
              />
            </Card>
          </ChartRow>
          <ChartRow>
            <Card width={"100%"} minWidth={"250px"}>
              <PieChart
                dados={localData}
                name="Locais com maiores números de infrações"
              />
            </Card>
            <Card width={"100%"} minWidth={"250px"}>
              <CardTitle>Pessoas atingidas por infrações</CardTitle>
              <BubbleChart
                data={bubbleData}
                name="Pessoas atingidas por infrações"
              />
            </Card>
          </ChartRow>
        </ChartHolder>
      </ReportHolder>
    </ReportContainer>
  );
}

export default Report;
