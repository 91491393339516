import { LeftArrow } from "@styled-icons/boxicons-solid/LeftArrow";
import { RightArrow } from "@styled-icons/boxicons-solid/RightArrow";
import styled from "styled-components";

export const RightArrowIcon = styled(RightArrow)`
  color: ${(props) => props.theme.color.text};
  width: 1rem;
  max-width: 10px;
`;

export const LeftArrowIcon = styled(LeftArrow)`
  color: ${(props) => props.theme.color.text};
  width: 1rem;
  max-width: 10px;
`;

export const TableContainer = styled.div``;

export const TableConfig = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
`;
export const ExportCSV = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
`;
export const DataFilter = styled.input.attrs({type: "date"})`
  width: 200px;
  height: 30px;
  border-radius: 4px;
  text-indent: 5px;
  margin-right: 10px;
  border: none;
  font-family: 'Roboto', sans-serif;
`;
export const ButtonCSV = styled.button`
  width: 200px;
  height: 30px;
  border-radius: 4px;
  padding: 0px;
  margin: 0px;
  outline: none;
  border: none;
  background-color: #0073EA;
  color: #FAFBFC;
  cursor: pointer;
`;

export const TableObject = styled.table`
  table-layout: fixed;
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
  background: ${(props) => props.theme.color.primary};
  box-shadow: 0 3px 5px #0000001d;
  overflow-x: scroll;
`;

export const TableBody = styled.tbody`
  tr:nth-child(even) {
    background: ${(props) => props.theme.color.background};
  }
`;
export const TableHeader = styled.thead`
  th:nth-child(1) {
    border-top-left-radius: 5px;
  }
  th:nth-last-child(1) {
    border-top-right-radius: 5px;
  }
`;
export const TableRow = styled.tr``;
export const TableData = styled.td`
  padding: 1rem;
  font-size: 1.5rem;
  word-wrap: break-word;
  span {
    display: inline-block;
    width: 3rem;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
`;
export const TableHead = styled.th`
  text-align: left;
  padding: 1rem;
  font-size: 1.5rem;
  color: #fafbfc;
  background: ${(props) => props.theme.color.secondary};
`;

export const TableSettings = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

export const TableSettingsActions = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;

export const TableDataContentHolder = styled.div`
  display: flex;
`;
