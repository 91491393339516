import { TextClearFormatting } from "@styled-icons/fluentui-system-filled/TextClearFormatting";
import styled from "styled-components";

export const ClearIcon = styled(TextClearFormatting)`
  color: ${(props) => props.theme.color.text};
  margin-bottom: 5px;
  width: 20px;
  cursor: pointer;
`;

export const TagContainer = styled.div``;

export const TagHeader = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TagTitle = styled.p``;

export const TagHolder = styled.div`
  width: 100%;
  height: 150px;
  background: ${(props) => props.theme.color.input.background};
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin-bottom: 1rem;
  border: 1px solid #3333331d;
`;

export const Tag = styled.div`
  border-radius: 10px;
  padding: 5px 8px;
  border: 1px solid #333;
  height: 20px;
  background: ${(props) => props.theme.color.background};
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin: 0 1px;
  justify-content: space-between;

  svg {
    margin-left: 5px;
  }
`;
