import styled from "styled-components";

export const NameContainer = styled.div``;

export const NameHolder = styled.div`
  position: absolute;
  z-index: 9999;
  width: 20rem;
  margin: 0.5rem 0 0 1rem;
`;

export const NameListHolder = styled.div`
  width: 100%;
  max-height: 39.6rem;
  background: ${(props) => props.theme.color.text};
  overflow: auto;
`;

export const NameList = styled.ul`
  list-style-type: none;
  width: 100%;
  padding: 10px;
  height: 100%;
`;

export const NameItem = styled.li`
  width: 100%;
  padding: 2px;
  font-size: 1.5rem;
  color: ${(props) => props.theme.color.background} !important;
  cursor: pointer;
`;
