import styled from "styled-components";

export const SettingsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
`;

export const SettingsContent = styled.div`
height: calc(100vh - 90px);
  margin-top: 110px;
`;
