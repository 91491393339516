import React from 'react';

import { RoundedButtonContainer, RoundedButton } from './styles';

function TableRoundedButton({ children, ...rest }) {
    return(
        <RoundedButtonContainer>
            <RoundedButton {...rest}>{children}</RoundedButton>
        </RoundedButtonContainer>        
    );
}

export default TableRoundedButton;