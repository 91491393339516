import styled from "styled-components";

export const KeywordsContainer = styled.div`
  width: 100%;
  min-width: 30px;
  height: 100%;
  overflow: auto;
  color: ${(props) => props.theme.color.text};
`;

export const KeywordsContent = styled.div`
  position: relative;
  z-index: 9;
  width: 100%;
  height: calc(100vh - 90px);
  margin-top: 110px;
`;
