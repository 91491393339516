import React from "react";
import { ButtonContainer, ButtonObject, MenuIcon } from "./styles";

function BurgerButton({ children, width, margin, ...rest }) {
  return (
    <ButtonContainer style={{ margin, width }}>
      <ButtonObject style={{ width }} {...rest}>
        {children}
        <MenuIcon />
      </ButtonObject>
    </ButtonContainer>
  );
}

export default BurgerButton;
