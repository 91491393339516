import styled from "styled-components";

export const HomeContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  color: ${(props) => props.theme.color.text};
`;

export const ContentContainer = styled.div`
  padding-left: 10%;
  display: flex;
  height: calc(100% - 130px);
  margin-top: 110px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-right: 10%;
`;

export const WelcomeUser = styled.p`
  font-weight: 300;
  font-size: 2rem;
  margin-bottom: 2rem;
`;

export const UserTextHolder = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const UserParagraph = styled.p`
  font-size: calc(1.4rem + 0.5vw);
  text-indent: 3rem;
  text-align: center;
`;

export const UserOrderedList = styled.ul`
  ul {
    margin-left: 2rem;
  }
`;

export const UserOrderedListItem = styled.li``;

export const Header = styled.h1`
  font-size: 3rem;
  height: 8rem;
  display: flex;
  align-items: center;
  padding-left: 50px;
  font-weight: 300;
  margin-bottom: 5rem;
  color: ${(props) => props.theme.color.text};
  border-bottom: 1px solid ${(props) => props.theme.color.stroke};
`;
